import * as Yup from "yup";
import I18n from "../I18n";

const MAX_FILE_SIZE_MB = 5;

const socialMediaUrlTest = Yup.string()
  .url(I18n.t('text.validation_errors.url'))
  .test(
    "atLeastOne",
    I18n.t('text.validation_errors.at_least_one'),
    function (value) {
      const { youtube, facebook, instagram, snapchat, tiktok, other } =
        this.parent || {};
      if (
        !youtube &&
        !facebook &&
        !instagram &&
        !snapchat &&
        !tiktok &&
        !other
      ) {
        return false;
      }
      return true;
    }
  );

const fileSizeTest = Yup.mixed().test(
  "fileSize",
  I18n.t('text.validation_errors.file_size',{MAX_FILE_SIZE_MB}),
  (value) => {
    return !value || value.size / (1024 * 1024) <= MAX_FILE_SIZE_MB;
  }
);

const FORM_VALIDATION = Yup.object().shape({
  firstName: Yup.string().required(I18n.t('text.validation_errors.required')),
  lastName: Yup.string().required(I18n.t('text.validation_errors.required')),
  gender: Yup.string().required(I18n.t('text.validation_errors.required')),
  birthdayDate: Yup.date().required(I18n.t('text.validation_errors.required')),
  parentalAuthorization: Yup.mixed()
    .test(
      "under18",
      I18n.t('text.validation_errors.under_18'),
      function (value) {
        const { birthdayDate } = this.parent || {};
        if (!birthdayDate) return true;

        const today = new Date();
        const dob = new Date(birthdayDate);
        const age = today.getFullYear() - dob.getFullYear();
        const monthDiff = today.getMonth() - dob.getMonth();
        const dayDiff = today.getDate() - dob.getDate();

        let adjustedAge = age;
        if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
          adjustedAge--;
        }

        return adjustedAge < 18 ? !!value : true;
      }
    )
    .concat(fileSizeTest),
  email: Yup.string().email(I18n.t('text.validation_errors.email')).required(I18n.t('text.validation_errors.required')),
  whatsAppNumber: Yup.number()
    .integer()
    .typeError(I18n.t('text.validation_errors.phone_number'))
    .required(I18n.t('text.validation_errors.required')),
  nationality: Yup.object().required(I18n.t('text.validation_errors.required')),
  countryOfResidence: Yup.object().required(I18n.t('text.validation_errors.required')),
  socialMediaName: Yup.string().required(I18n.t('text.validation_errors.required')),
  categories: Yup.object().required(I18n.t('text.validation_errors.required')),
  portrait: Yup.mixed()
    .concat(fileSizeTest)
    .required(I18n.t('text.validation_errors.file_upload')),
  youtube: socialMediaUrlTest,
  facebook: socialMediaUrlTest,
  instagram: socialMediaUrlTest,
  snapchat: socialMediaUrlTest,
  tiktok: socialMediaUrlTest,
  other: socialMediaUrlTest,
  termsOfService: Yup.boolean()
    .oneOf([true], I18n.t('text.validation_errors.terms_and_condition'))
    .required(I18n.t('text.validation_errors.terms_and_condition')),
});

export default FORM_VALIDATION;
