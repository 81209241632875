import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import { useField, useFormikContext } from "formik";
import I18n from "../../../I18n";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const StyledFileUploadContainer = styled(Grid)({
  backgroundColor: "#ffe68d30",
  borderRadius: 5,
  margin: 10,
  maxWidth: "97%",
  padding: 10,
  textAlign: "center",
});

const FileUpload = ({ name, label, ...otherProps }) => {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  const handleChange = (event) => {
    const file = event.currentTarget.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setFieldValue(name, file);
      // setFieldValue(name, reader.result);
    };
  };

  return (
    // <Grid container spacing={2} alignItems="center">
    <StyledFileUploadContainer container spacing={2} alignItems="center">
      <Grid item xs={12}>
        <Typography>{label}</Typography>
      </Grid>
      <Grid item xs={12}>
        {field.value && field.value.name && (
          <Typography variant="caption" color="textSecondary">
            {field.value.name}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        {meta.error && (
          <Typography color="error" variant="caption">
            {meta.error}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        <Button
          component="label"
          role={undefined}
          variant="contained"
          startIcon={<span style={{ paddingTop: 10 + 'px', paddingLeft: 8 + 'px' }}><CloudUploadIcon /></span>}
        >
          {I18n.t("text.upload_file")}
          <VisuallyHiddenInput
            type="file"
            name={name}
            accept="image/jpeg, image/png"
            onChange={handleChange}
            {...otherProps}
          />
        </Button>
      </Grid>
    </StyledFileUploadContainer>
  );
};

export default FileUpload;
