import React, { useEffect, useState, useCallback } from "react";
import Header from "./Components/Header";
import { Formik, Form } from "formik";

import { Container, Grid, Typography, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Textfield from "./Components/FormsUI/Textfield";
import Select from "./Components/FormsUI/Select";
import DateTimePicker from "./Components/FormsUI/DateTimePicker";
import Checkbox from "./Components/FormsUI/Checkbox";
import Button from "./Components/FormsUI/Button";
import FileUpload from "./Components/FormsUI/FileUpload";
import LocalSelector from "./I18n/Components/Selector";

import genders from "./data/genders.json";

import INITIAL_FORM_STATE from "./Config/intialFormState";
import FORM_VALIDATION from "./Config/formValidation";
import useStyles from "./Config/styles";
import FileUploadAvatar from "./Components/FormsUI/FileUploadAvatar";

import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import I18n, { dir } from "./I18n";
import { fetchCategory, fetchCountry, registerInfluencer } from "./Http";
import { fileToBase64 } from "./Utils";
import moment from "moment";
import ReCAPTCHA from "react-google-recaptcha";
import config from "./Config";
import ReactGA from "react-ga4";

ReactGA.event("page_view");

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function openTermsAndConditions(event) {
  event.preventDefault();
  let link = null;
  switch (I18n.locale) {
    case "ar":
      link =
        "https://drive.google.com/file/d/1ZSMBy5Tyd94sno2OOER5HLyGCOy68yJ4/view";
      break;
    case "en":
    default:
      link =
        "https://drive.google.com/file/d/1Vjs07ZdRfVGOJeszyMVQh2-hti3YozG8/view";
      break;
  }
  link && window.open(link, "_blank").focus();
}

const recaptchaRef = React.createRef();

const App = () => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    autoHideDuration: 6000,
    severity: "error",
    message: null,
  });
  const [closed, setClosed] = useState(true);
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const [generalState, setGeneralState] = useState({
    loading: false,
    success: false,
  });

  const [selectedAge, setSelectedAge] = useState(18);

  const handleAgeChange = useCallback(
    (event) => {
      const { value } = event.target;
      const selectedBirthdayDate = moment(value);
      const today = moment();
      const age = today.diff(selectedBirthdayDate, "years");
      setSelectedAge(age);
    },
    [setSelectedAge]
  );

  const [categories, setCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    fetchCategory()
      .then((result) => {
        const { datas } = result;
        setCategories(datas);
      })
      .catch((error) => {
        setCategories([]);
        setSnackbar({
          ...snackbar,
          open: true,
          severity: "error",
          message: I18n.t("text.error.http_error"),
        });
      });
    fetchCountry()
      .then((result) => {
        const { datas } = result;
        setCountries(datas);
      })
      .catch((error) => {
        setCountries([]);
        setSnackbar({
          ...snackbar,
          open: true,
          severity: "error",
          message: I18n.t("text.error.http_error"),
        });
      });
  }, []);
  const classes = useStyles();
  const theme = createTheme({
    direction: dir,
    palette: {
      type: "dark",
      primary: {
        main: "#ffe68d",
      },
      secondary: {
        main: "#000000",
      },
    },
    typography: {
      fontFamily: [
        "Raleway",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
  });

  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const onRecaptchaChange = function (value) {
    setRecaptchaToken(value);
  };
  const resetRecaptcha = function () {
    recaptchaRef?.current?.reset();
  };

  const submit = async function (formik) {
    setSnackbar({ ...snackbar, open: false, severity: "error", message: null });
    setGeneralState({ ...generalState, loading: true });
    await formik.submitForm();
    if (!formik.isValid) {
      setGeneralState({ ...generalState, loading: false });
      setSnackbar({
        ...snackbar,
        open: true,
        severity: "error",
        message: I18n.t("text.validation_errors.submit"),
      });
      return;
    }
    if (!recaptchaToken) {
      setGeneralState({ ...generalState, loading: false });
      setSnackbar({
        ...snackbar,
        open: true,
        severity: "error",
        message: I18n.t("text.validation_errors.recaptcha"),
      });
      return;
    }
    const values = formik.values;
    const social_network_ids = [
      { code: "facebook", link: values.facebook },
      { code: "youtube", link: values.youtube },
      { code: "instagram", link: values.instagram },
      { code: "snapchat", link: values.snapchat },
      { code: "tiktok", link: values.tiktok },
      { code: "other", link: values.other },
    ];
    const http_values = {
      recaptcha_token: recaptchaToken,
      first_name: values.firstName,
      last_name: values.lastName,
      gender: values.gender,
      date_of_birth: moment(values.birthdayDate).format("YYYY-MM-DD"),
      parental_authorization:
        (values.parentalAuthorization &&
          (await fileToBase64(values.parentalAuthorization))) ||
        null,
      email: values.email,
      whatsapp_number: values.whatsAppNumber,
      nationality: values.nationality.id,
      country_of_residence: values.countryOfResidence.id,
      social_media_name: values.socialMediaName,
      category: values.categories.id,
      image_1920:
        (values.portrait && (await fileToBase64(values.portrait))) || null,
      social_network_ids: social_network_ids.filter(
        (e) => e.link !== "" && e.link !== null
      ),
    };

    registerInfluencer(http_values)
      .then((result) => {
        setGeneralState({ ...generalState, loading: false });
        const { success, error: server_error } = result;
        if (success) {
          setGeneralState({ ...generalState, success: true });
          setSnackbar({
            ...snackbar,
            open: true,
            severity: "success",
            message: server_error || I18n.t("text.success.register"),
          });
          ReactGA.event("sign_up");
        } else {
          resetRecaptcha();
          setSnackbar({
            ...snackbar,
            open: true,
            message: server_error || I18n.t("text.error.undefined"),
          });
        }
      })
      .catch((rejected_error) => {
        resetRecaptcha();
        setGeneralState({ ...generalState, loading: false });
        setSnackbar({
          ...snackbar,
          open: true,
          message: rejected_error?.error || I18n.t("text.error.undefined"),
        });
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.pageStyle}>
        <Grid container>
          <Grid item xs={12}>
            <Header />
          </Grid>
          {closed ? (
            <Grid item xs={9} className={classes.formWrapperClosed}>
              <Typography
                variant="h4"
                color="primary"
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {/* {I18n.t("text.registrations_closed")} */}
                {"Registrations closed"}
              </Typography>
            </Grid>
          ) : (
            <Grid item xs={12} className={classes.pageStyle}>
              <Container maxWidth="md">
                <div className={classes.formWrapper} dir={dir}>
                  {!generalState.success && (
                    <Formik
                      initialValues={{
                        ...INITIAL_FORM_STATE,
                      }}
                      validationSchema={FORM_VALIDATION}
                      onSubmit={(values) => {}}
                    >
                      {(formik) => (
                        <Form>
                          <Grid container spacing={2}>
                            <Grid item xs={9}>
                              <Typography
                                variant="h4"
                                color="primary"
                                style={{ fontWeight: "bold" }}
                              >
                                {I18n.t("title.general_information")}
                              </Typography>
                              <Typography variant="subtitle1">
                                {I18n.t("text.private_information")}
                              </Typography>
                            </Grid>
                            <Grid container xs={3} justifyContent="flex-end">
                              <LocalSelector />
                            </Grid>

                            <Grid item xs={6}>
                              <Textfield
                                name="firstName"
                                label={I18n.t("label.first_name")}
                              />
                            </Grid>

                            <Grid item xs={6}>
                              <Textfield
                                name="lastName"
                                label={I18n.t("label.last_name")}
                              />
                            </Grid>

                            <Grid item xs={6}>
                              <Select
                                name="gender"
                                label={I18n.t("label.gender")}
                                options={Object.keys(genders)}
                                getOptionKey={(item) => item}
                                getOptionLabel={(item) =>
                                  item ? I18n.t(`text.gender.${item}`) : ""
                                }
                              />
                            </Grid>

                            <Grid item xs={6}>
                              <DateTimePicker
                                name="birthdayDate"
                                label={I18n.t("label.date_of_birth")}
                                onBlur={handleAgeChange}
                              />
                            </Grid>
                            {selectedAge < 18 && (
                              <Grid item xs={12}>
                                <Typography variant="h6" color="primary">
                                  {I18n.t("title.parental_authorization")}
                                </Typography>
                                <Typography variant="subtitle2">
                                  {I18n.t("text.parental_authorization")}
                                </Typography>
                                <FileUpload
                                  name="parentalAuthorization"
                                  label={I18n.t(
                                    "text.upload_parental_authorization"
                                  )}
                                />
                              </Grid>
                            )}
                            <Grid item xs={12} sm={6}>
                              <Textfield
                                name="email"
                                label={I18n.t("label.email")}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <Textfield
                                name="whatsAppNumber"
                                label={I18n.t("label.whatsapp_number")}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <Select
                                name="nationality"
                                label={I18n.t("label.nationality")}
                                options={countries}
                                getOptionKey={(item) => item.id}
                                getOptionLabel={(item) => item.name}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <Select
                                name="countryOfResidence"
                                label={I18n.t("label.country_of_residence")}
                                options={countries}
                                getOptionKey={(item) => item.id}
                                getOptionLabel={(item) => item.name}
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <Typography
                                variant="h4"
                                color="primary"
                                style={{ fontWeight: "bold" }}
                              >
                                {I18n.t("title.submission")}
                              </Typography>
                              <Typography variant="subtitle1">
                                {I18n.t("text.submission_message")}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <Textfield
                                name="socialMediaName"
                                label={I18n.t("label.social_media_name")}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <Select
                                name="categories"
                                label={I18n.t("label.category")}
                                options={categories}
                                getOptionKey={(item) => item.id}
                                getOptionLabel={(item) => item.name}
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <Typography variant="h6" color="primary">
                                {I18n.t("title.portrait")}
                              </Typography>
                              <Typography variant="subtitle2">
                                {I18n.t("text.portrait_requirements")}
                              </Typography>
                              <FileUploadAvatar
                                name="portrait"
                                label={I18n.t("text.portrait_upload")}
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <Typography variant="h6" color="primary">
                                {I18n.t("title.submission")}
                              </Typography>
                              <Typography>
                                {I18n.t(
                                  "text.social_media_accounts_requirements"
                                )}
                              </Typography>
                            </Grid>

                            <Grid item xs={6}>
                              <Textfield
                                name="youtube"
                                label={I18n.t("label.youtube")}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Textfield
                                name="facebook"
                                label={I18n.t("label.facebook")}
                              />
                            </Grid>

                            <Grid item xs={6}>
                              <Textfield
                                name="instagram"
                                label={I18n.t("label.instagram")}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Textfield
                                name="snapchat"
                                label={I18n.t("label.snapchat")}
                              />
                            </Grid>

                            <Grid item xs={6}>
                              <Textfield
                                name="tiktok"
                                label={I18n.t("label.tiktok")}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Textfield
                                name="other"
                                label={I18n.t("label.other")}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <Checkbox
                                id="termsOfService"
                                name="termsOfService"
                                legend={
                                  <Typography variant="h6" color="primary">
                                    {I18n.t("title.general_terms")}
                                  </Typography>
                                }
                                labelStyle={{ zIndex: 3 }}
                                label={
                                  <label htmlFor="termsOfService">
                                    <a
                                      onClick={openTermsAndConditions}
                                      rel="noreferrer"
                                      style={{ color: "#ffe68d" }}
                                    >
                                      {I18n.t("label.general_terms_agreement")}
                                    </a>
                                  </label>
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <ReCAPTCHA
                                sitekey={
                                  config.service_provider.google_recaptch.key
                                }
                                onChange={onRecaptchaChange}
                                ref={recaptchaRef}
                              />
                            </Grid>

                            <Grid
                              container
                              justifyContent="center"
                              item
                              xs={4}
                              sm={3}
                              style={{ margin: "auto" }}
                            >
                              <Button
                                size="large"
                                variant="outlined"
                                type="submit"
                                className={classes.button}
                                onClick={() => submit(formik)}
                                disabled={generalState.loading}
                              >
                                <Typography
                                  variant="body1"
                                  style={{ fontWeight: "bold" }}
                                >
                                  {I18n.t("text.register")}
                                </Typography>
                              </Button>
                            </Grid>
                          </Grid>
                        </Form>
                      )}
                    </Formik>
                  )}
                  {generalState.success && (
                    <div>
                      <Typography
                        variant="h4"
                        color="primary"
                        style={{ fontWeight: "bold" }}
                      >
                        {I18n.t("text.success.register")}
                      </Typography>
                    </div>
                  )}
                </div>
              </Container>
            </Grid>
          )}
        </Grid>
        {!closed && (
          <Snackbar
            open={snackbar.open}
            autoHideDuration={snackbar.autoHideDuration}
            onClose={handleCloseSnackbar}
          >
            <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
              {snackbar.message || "-"}
            </Alert>
          </Snackbar>
        )}
        ;
      </div>
    </ThemeProvider>
  );
};

export default App;
