const config = {
    api: {
        baseUrl: "https://platform.digitalcreatorawards.com/api"
    },
    service_provider: {
        google_recaptch: {
            key: "6LfSQdspAAAAAMSmOzGCtMrAIHhkX7-cpLd2R4u0"
        },
        ga4:{
            tag:"G-FXXEFKWZL1"
        }
    }
}

export default config;