import React from "react";
import { Toolbar, AppBar } from "@material-ui/core";

const Header = () => {
  return (
    <AppBar position="static" color="secondary">
      <Toolbar>
        <a
          href="https://digitalcreatorawards.com/"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            textDecoration: "none",
            margin: "auto",
            display: "flex",
            width: "max-content",
          }}
        >
          <img
            src="/dca-logo.png"
            alt="Logo"
            width={200}
            style={{
              paddingLeft: "10px",
              paddingTop: "50px",
              paddingBottom: "10px",
              margin: "auto",
            }}
          />
        </a>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
