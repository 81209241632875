import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import config from "./Config";
import ReactGA from "react-ga4";
import {dir} from './I18n';

ReactGA.initialize(config.service_provider.ga4.tag);
document.body.setAttribute('dir',dir);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
