const INITIAL_FORM_STATE = {
  firstName: "",
  lastName: "",

  gender: "",
  birthdayDate: "",

  parentalAuthorization: null,

  email: "",
  whatsAppNumber: "",

  nationality: null,
  countryOfResidence: null,

  socialMediaName: "",
  categories: null,

  portrait: null,

  youtube: "",
  facebook: "",
  instagram: "",
  snapchat: "",
  tiktok: "",
  other: "",

  termsOfService: false,
};

export default INITIAL_FORM_STATE;
