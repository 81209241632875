import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(8),
    padding: theme.spacing(3),
    color: "#fff",
    border: "1px solid #ffe68d",
    borderRadius: "5px",
    boxShadow: "-1px 1px 5px 0px rgba(212, 1, 1, 0.75)",
    WebkitBoxShadow: "-1px 1px 5px 0px rgba(212, 1, 1, 0.75)",
    MozBoxShadow: "-1px 1px 5px 0px rgba(212, 1, 1, 0.75)",
    background:
      "linear-gradient(45deg, #4c4c4c 0%, #595959 12%, #666666 25%, #474747 39%, #2c2c2c 50%, #000000 51%, #111111 60%, #2b2b2b 76%, #1c1c1c 91%, #131313 100%)",
  },
  formWrapperClosed: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(8),
    marginLeft: "auto",
    marginRight: "auto",
    padding: theme.spacing(6),
    color: "#fff",
    border: "1px solid #ffe68d",
    borderRadius: "5px",
    boxShadow: "-1px 1px 5px 0px rgba(212, 1, 1, 0.75)",
    WebkitBoxShadow: "-1px 1px 5px 0px rgba(212, 1, 1, 0.75)",
    MozBoxShadow: "-1px 1px 5px 0px rgba(212, 1, 1, 0.75)",
    background:
      "linear-gradient(45deg, #4c4c4c 0%, #595959 12%, #666666 25%, #474747 39%, #2c2c2c 50%, #000000 51%, #111111 60%, #2b2b2b 76%, #1c1c1c 91%, #131313 100%)",
  },
  pageStyle: {
    background: "#000000",
    minHeight: "100vh",
  },
  textFieldCustum: {
    border: "1px solid #ffe68d",
  },
  button: {
    width: "auto",
  },
}));

export default useStyles;
