export default {
    title: {
        general_information: "General Informations",
        parental_authorization: "Parental Authorization",
        submission: "Submission",
        portrait: "Portrait",
        social_media_accounts: "Social Media Accounts",
        general_terms: "General terms and conditions",
    },
    text: {
        private_information: "The given informations in this section are strictly private",
        parental_authorization: "Parental authorization required for those under 18",
        upload_parental_authorization: "UPLOAD YOUR PARENTAL AUTHORIZATION",
        upload_file: "Upload file",
        submission_message: "The provided information will appear on the Digital Creator Awards voting page upon approval of submission.",
        portrait_requirements: "The Dimensions required are 180*180px",
        portrait_upload: "Upload your profile picture",
        social_media_accounts_requirements: "At least one URL must be filled",
        register: "Register",
        gender: {
            male: "Male",
            female: "Female"
        },
        validation_errors: {
            submit: "There is some errors in your data, please check the form.",
            required: "Required",
            email: "Invalid email.",
            url: "Invalid URL format",
            at_least_one: "At least one social media URL is required",
            file_size: "File size should not exceed {{MAX_FILE_SIZE_MB}} MB",
            under_18: "Parental authorization is required for users under 18",
            phone_number: "Please enter a valid phone number",
            file_upload: "File upload is required",
            terms_and_condition: "The terms and conditions must be accepted.",
            recaptcha: "Please check the Google Recaptcha validation."
        },
        error: {
            undefined: "Error, please contact our support.",
            http_error: "Http Error please refresh the page or contact our support."
        },
        success: {
            register: "Registration with success."
        }
    },
    label: {
        language: "Language",
        first_name: "First Name",
        last_name: "Last Name",
        gender: "Gender",
        date_of_birth: "Date of birth",
        email: "Email",
        whatsapp_number: "WhatsApp Number",
        nationality: "Nationality",
        country_of_residence: "Country Of Residence",
        social_media_name: "Social Media Name",
        category: "Category",
        portrait: "Portrait",
        youtube: "Youtube",
        facebook: "Facebook",
        instagram: "Instagram",
        snapchat: "Snapchat",
        tiktok: "TikTok",
        other: "Other",
        general_terms_agreement: "I agree to the general terms and conditions.",
    }
}