import React from "react";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useField, useFormikContext } from "formik";
import I18n from '../../../I18n';

const SelectWrapper = ({ name, options, getOptionKey, getOptionLabel, ...otherProps }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (_, value) => {
    setFieldValue(name, value);
  };

  const configSelect = {
    ...field,
    ...otherProps,
    variant: "outlined",
    fullWidth: true,
    onChange: handleChange,
  };

  const has_error = meta && meta.touched && meta.error;

  return (
    <Autocomplete
      {...configSelect}
      options={options}
      getOptionKey={getOptionKey}
      getOptionLabel={getOptionLabel}
      value={field.value || null}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={otherProps.label}
          error={has_error}
          helperText={has_error && I18n.t('text.validation_errors.required')}
        />
      )}
    />
  );
};

export default SelectWrapper;
