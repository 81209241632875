export default {
    title: {
        general_information: "المعلومات العامة",
        parental_authorization: "تفويض ولي الامر",
        submission: "تقديم",
        portrait: "صورة شخصية",
        social_media_accounts: "حسابات وسائل التواصل الاجتماعي",
        general_terms: "الشروط والأحكام العامة",
    },
    text: {
        private_information: "المعلومات المقدمة في هذا القسم محمية بشكل صارم",
        parental_authorization: "تفويض ولي الامر إجباري للأشخاص الذين تقل اعمارهم عن 18 عاماً",
        upload_parental_authorization: "تحميل تفويض الوالدين الخاص بك",
        upload_file: "تحميل الملف",
        submission_message: "ستظهر معلوماتكم المقدمة أدناه على صفحة التصويت لجوائز الديجيتال كرييتر اواردز بعد الموافقة على تقديمكم.",
        portrait_requirements: "الأبعاد المطلوبة هي 180 × 180 بكسل",
        portrait_upload: "تحميل صورة الملف الشخصي الخاصة بك",
        social_media_accounts_requirements: "يجب ملء عنوان URL واحد على الأقل",
        register: "تسجيل",
        gender:{
            male:"ذكر",
            female:"أنثى"
        },
        validation_errors: {
            submit: "هناك بعض الأخطاء في بياناتك، يرجى التحقق من النموذج.",
            required: "مطلوب",
            email: "بريد إلكتروني غير صالح.",
            url: "تنسيق عنوان URL غير صالح",
            at_least_one: "مطلوب على الأقل عنوان URL لوسائل التواصل الاجتماعي واحد على الأقل",
            file_size: "يجب ألا يتجاوز حجم الملف {{MAX_FILE_SIZE_MB}} ميغابايت",
            under_18: "تفويض ولي الامر إجباري للأشخاص الذين تقل اعمارهم عن 18 عاماً",
            phone_number: "يرجى إدخال رقم هاتف صحيح",
            file_upload: "تحميل الملف مطلوب",
            terms_and_condition: "يجب قبول الشروط والأحكام.",
            recaptcha: "يرجى التحقق من صحة Google reCAPTCHA."
        },
        error: {
            undefined: "خطأ، يرجى الاتصال بدعمنا.",
            http_error: "خطأ HTTP، يرجى تحديث الصفحة أو الاتصال بدعمنا."
        },
        success: {
            register: "تم التسجيل بنجاح."
        }
    },
    label: {
        language: "اللغة",
        first_name: "الاسم الأول",
        last_name: "اسم العائلة",
        gender: "الجنس",
        date_of_birth: "تاريخ الميلاد",
        email: "البريد الإلكتروني",
        whatsapp_number: "رقم الواتساب",
        nationality: "الجنسية",
        country_of_residence: "بلد الإقامة",
        social_media_name: "اسم وسائل التواصل الاجتماعي",
        category: "الفئة",
        portrait: "الصورة الشخصية",
        youtube: "يوتيوب",
        facebook: "فيسبوك",
        instagram: "انستجرام",
        snapchat: "سناب شات",
        tiktok: "تيك توك",
        other: "آخر",
        general_terms_agreement: "أوافق على الشروط والأحكام العامة.",
    }
}
