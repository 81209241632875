import axios from 'axios';
import { resources } from 'x-resources';
import config from "../Config";
import i18n from "../I18n";

function getHttpLocale() {
    let locale = null;
    switch (i18n.locale) {
        case 'ar':
            locale = 'ar_001';
            break;
        case 'en':
        default:
            locale = 'en_US';
            break;
    }
    return locale;
}

const baseUrl = `${config.api.baseUrl}/dca_vote`;
const axiosInstance = axios.create({ timeout: 600000 });

export function category() {
    return resources(`${baseUrl}/category`, {
        query: { method: 'POST', url: '/query' },
    }, { axiosInstance })
}

export async function fetchCategory() {
    return new Promise((resolve, reject) => {
        category().query({ lang: getHttpLocale() }).then(response => {
            const { success = false, datas = [] } = response.data;
            if (success) {
                resolve({ success, datas })
            } else {
                reject({ success, datas })
            }
        }, error => reject({ success: false, datas: [], error: error && error.message || null }))
    });
}

export function country() {
    return resources(`${baseUrl}/country`, {
        query: { method: 'POST', url: '/query' },
    }, { axiosInstance })
}

export async function fetchCountry() {
    return new Promise((resolve, reject) => {
        country().query({ lang: getHttpLocale() }).then(response => {
            const { success = false, datas = [] } = response.data;
            if (success) {
                resolve({ success, datas })
            } else {
                reject({ success, datas })
            }
        }, error => reject({ success: false, datas: [], error: error && error.message || null }))
    });
}

export function influencer() {
    return resources(`${baseUrl}/influencer`, {
        register: { method: 'POST', url: '/register' },
    }, { axiosInstance })
}

export async function registerInfluencer(data) {
    return new Promise((resolve, reject) => {
        influencer().register({ lang: getHttpLocale(), data }).then(response => {
            const { success = false, error = null } = response.data;
            if (success) {
                resolve({ success, error });
            } else {
                reject({ success, error });
            }
        }, error => reject({ success: false, error: error && error.message || null }))
    });
}