import React from "react";
import { Button } from "@material-ui/core";

const ButtonWrapper = ({ children, onClick, disabled = false, ...otherProps }) => {
  const configButton = {
    variant: "contained",
    color: "primary",
    fullWidth: true,
    onClick,
    disabled,
  };
  return (
    <Button {...configButton}>
      {disabled ? "Submitting..." : children}
    </Button>
  );
};

export default ButtonWrapper;
