import React, { useState } from "react";
import { TextField, MenuItem } from "@material-ui/core";
import I18n from "../index";

const Component = function () {
  const [value, setValue] = useState(I18n.locale);

  function onchange(event) {
    const value = event.target.value;
    if (!value)
      return;
    setValue(value);
    window.localStorage.setItem("lang", value);
    window.location.reload();
  }
  return (
    <TextField
      label={I18n.t("label.language")}
      select={true}
      value={value}
      variant={"outlined"}
      fullWidth={true}
      onChange={onchange}
    >
      <MenuItem value={"ar"}>AR</MenuItem>
      <MenuItem value={"en"}>EN</MenuItem>
    </TextField>
  );
};

export default Component;
