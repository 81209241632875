import { I18n } from "i18n-js";
import en from "./Translations/en";
import ar from "./Translations/ar";


const i18n = new I18n({ en, ar });
i18n.defaultLocale = "en";
const locale = window.localStorage.getItem("lang") || "en";
i18n.locale = locale;
i18n.fallbacks = true;
export const dir = locale === "ar" && "rtl" || "ltr";
export default i18n;